import React, { useEffect } from "react";
import Layout from "../containers/Layout/Layout";
import { navigate } from "gatsby";
import ThankYouComponent from "../components/ThankYouComponent/ThankYouComponent";

const Thanks = (props: any) => {
    const { location } = props;

    const redirected = location.state && location.state.redirected;

    useEffect(() => {
        !redirected && navigate("/");
    }, [redirected]);

    const { pageContext } = props;
    return (
        <Layout {...pageContext} changeBackground={true}>
            <ThankYouComponent type={"thanks"} />
        </Layout>
    );
};

export default Thanks;
